<template>
  <c-flex my="2em" wrap="wrap">
    <GridTable
      title="Key Resources"
      :columns="tableColumns"
      :addRowLabel="'Add new resource'"
      :onAddRow="addResource"
      :disabled="disabled"
    >
      <tr
        v-for="(resource, index) in resources"
        :key="resource.id || resource.tempId"
      >
        <td class="grid__table__body__cell">
          <c-flex h="100%" justify="center" align-items="start">
            <c-popover placement="bottom" v-slot="{}">
              <c-popover-trigger cursor="pointer">
                <c-box
                  my="2"
                  v-chakra="{
                    height: 'auto',
                  }"
                >
                  <svg
                    v-chakra="{
                      width: '10px',
                      height: '16px',
                      fill: '#ef5323',
                    }"
                  >
                    <use href="@/assets/icons/vertical-dots.svg#dots" />
                  </svg>
                </c-box>
              </c-popover-trigger>
              <c-popover-content maxWidth="150px" zIndex="modal">
                <c-popover-body p="0">
                  <c-list fontSize="sm">
                    <c-list-item v-if="resource.id">
                      <c-box
                        cursor="pointer"
                        w="100%"
                        px="2"
                        py="2"
                        @click="deleteResource(index)"
                        >Delete</c-box
                      >
                    </c-list-item>
                  </c-list>
                </c-popover-body>
              </c-popover-content>
            </c-popover>

            <c-flex
              direction="column"
              align="center"
              textAlign="center"
              flex="1"
              ml="3"
              mr="auto"
            >
              <c-editable
                fontSize="lg"
                maxWidth="100%"
                fontWeight="600"
                my="2"
                :default-value="resource.name"
                :isDisabled="disabled"
                @change="onNameInputChange($event, index)"
              >
                <c-editable-preview maxWidth="100%" overflowWrap="break-word" />
                <c-editable-input @blur="onFieldBlur(index)" />
              </c-editable>
              <c-text
                v-if="!resource.isNameValid"
                color="vc-orange.400"
                fontSize="8px"
              >
                Name is required to add resource
              </c-text>
            </c-flex>
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-box w="100%" h="100%" pt="4">
            <v-select
              :clearable="false"
              :searchable="false"
              class="vc-select"
              :reduce="(resourceType) => resourceType.value"
              v-model="resource.type"
              :options="resourceTypes"
              :disabled="disabled"
              placeholder="select"
              @input="
                () => {
                  onFieldBlur(index);
                }
              "
            ></v-select>
          </c-box>
        </td>
        <td class="grid__table__body__cell">
          <EssenceSelect
            v-model="resource.essence"
            @input="
              () => {
                onFieldBlur(index);
              }
            "
          />
        </td>

        <td class="grid__table__body__cell">
          <div>
            <span
              v-for="(activity, i) in resource.resources_activities"
              :key="i"
            >
              <c-tag
                size="sm"
                variant="solid"
                variant-color="cyan"
                mt="2"
                v-if="activity.key_activity"
              >
                <c-tag-label>{{ activity.key_activity.name }}</c-tag-label>
                <c-tag-close-button @click="removeActivity(activity, index)" />
              </c-tag>
            </span>

            <br />
            <br />
            <c-select
              v-model="activityId"
              placeholder="Add a key activity"
              @change="onActivityChange($event, index)"
              size="sm"
            >
              <option
                :value="activity.id"
                v-for="(activity, idx) in activities"
                :key="idx"
              >
                {{ activity.name }}
              </option>
            </c-select>
          </div>
        </td>

        <td class="grid__table__body__cell">
          <c-text as="span" color="#ef5323" fontSize="14px" v-if="saved"
            ><b>Saved successfully 👍</b></c-text
          >
          <EditableText
            :type="`Key Resources${index + 1}`"
            title="Key Resources"
            v-model="resource.description"
            @updateDescription="updateDescription"
            :editabelIndex="index"
            :disabled="disabled"
          />
        </td>
        <td class="grid__table__body__cell">
          <c-flex py="3">
            <AppSelect
              :options="teamMembers"
              label="id"
              placeholder="Select team member"
              :reduce="(member) => member.id"
              v-model="resource.employeeResponsible"
              :reset="true"
              :hasShadow="true"
              indicator-fill="orange"
              color="dark"
              size="large"
              :appendToBody="true"
              @input="
                (e) => {
                  onFieldBlur(index);
                }
              "
            >
              <template v-slot:option="{ option }">
                <c-text>
                  {{ option.user.firstname }}
                  {{ option.user.lastname }}
                </c-text>
              </template>
              <template v-slot:selected-option="{ option }">
                <c-text>
                  {{ option.user.firstname }}
                  {{ option.user.lastname }}
                </c-text>
              </template>
            </AppSelect>
          </c-flex>
        </td>
        <td class="grid__table__body__cell">
          <c-flex py="3">
            <c-flex justify="space-between">
              <AppSelect
                :options="plans"
                label="label"
                :reduce="(plan) => plan.value"
                :value="resource.actionPlan"
                :reset="true"
                :hasShadow="true"
                indicator-fill="orange"
                color="dark"
                size="large"
                :appendToBody="true"
                @input="onActionPlanChange($event, index)"
              />
              <c-spinner
                v-if="isUpdatingPlan"
                thickness="1px"
                color="blue.500"
              ></c-spinner>
            </c-flex>
          </c-flex>
          <MilestoneCell
            v-if="resource.milestone"
            :milestone="resource.milestone"
            ref="milestoneCell"
            @removeMilestone="onRemoveMilestone(index)"
          />
        </td>
      </tr>
    </GridTable>
    <!-- <c-flex
      :w="{ base: '100%', lg: '250px' }"
      h="255px"
      :mr="{ base: '0px', lg: '1em' }"
      mb="1.5em"
      cursor="pointer"
      borderColor="#2d2d2d4d"
      borderRadius="4px"
      borderWidth="1px"
      direction="column"
      justify="center"
      align="center"
      tabindex="-1"
      v-if="!disabled"
    >
      <c-pseudo-box
        as="a"
        display="flex"
        h="100%"
        :_hover="{ textDecoration: 'none' }"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        @click="addResource"
      >
        <svg
          v-chakra="{
            width: '30px',
            height: '30px',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
        </svg>
        <c-text color="vc-orange.400" fontWeight="600" fontSize="lg">
          Add Resources
        </c-text>
      </c-pseudo-box>
    </c-flex>
    <c-flex
      :w="{ base: '100%', lg: '250px' }"
      h="255px"
      :mr="{ base: '0px', lg: '1em' }"
      mb="1.5em"
      borderColor="#2d2d2d"
      borderRadius="4px"
      borderWidth="1px"
      direction="column"
      v-for="(resource, index) in resources"
      :key="resource.id || resource.tempId"
      paddingTop="25px"
      paddingInline="20px"
      class="resource"
      position="relative"
    >
      <c-popover v-if="!disabled" placement="bottom">
        <c-popover-trigger
          position="absolute"
          right="15px"
          top="15px"
          cursor="pointer"
        >
          <c-box>
            <svg
              v-chakra="{
                width: '10px',
                height: '16px',
                fill: '#ef5323',
              }"
            >
              <use href="@/assets/icons/vertical-dots.svg#dots"></use>
            </svg>
          </c-box>
        </c-popover-trigger>
        <c-popover-content maxWidth="150px" zIndex="modal">
          <c-popover-body>
            <c-list>
              <c-list-item>
                <c-box cursor="pointer" w="100%" @click="deleteResource(index)">
                  Delete
                </c-box>
              </c-list-item>
            </c-list>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
      <c-flex direction="column">
        <v-select
          :clearable="false"
          :searchable="false"
          class="vc-select"
          :reduce="(resourceType) => resourceType.value"
          v-model="resource.type"
          :options="resourceTypes"
          :disabled="disabled"
          placeholder="select"
          @input="
            () => {
              onFieldBlur(index);
            }
          "
        ></v-select>
        <EssenceSelect
          v-model="resource.essence"
          @input="
            () => {
              onFieldBlur(index);
            }
          "
        />
      </c-flex>
      <c-editable
        fontSize="lg"
        maxWidth="100%"
        fontWeight="600"
        my="2"
        :default-value="resource.name"
        :isDisabled="disabled"
        @change="onNameInputChange($event, index)"
      >
        <c-editable-preview maxWidth="100%" overflowWrap="break-word" />
        <c-editable-input @blur="onFieldBlur(index)" />
      </c-editable>
      <c-text v-if="!resource.isNameValid" color="vc-orange.400" fontSize="8px">
        Name is required to add resource
      </c-text>
      <ListTextInput
        v-chakra="{ height: 'calc(100% - 100px)' }"
        :title="''"
        :showTitle="false"
        :value="resource.description"
        @blur="onListInputBlur($event, index)"
        :disabled="disabled"
      />
    </c-flex> -->
    <c-modal
      :is-open="isMilestoneModalOpen"
      :on-close="closeMilestoneModal"
      is-centered
      size="xl"
      zIndex="modal"
    >
      <c-modal-content ref="content" zIndex="modal">
        <c-modal-header>
          <c-flex>
            Add
            <c-text textTransform="capitalize" ml="1"> Milestone </c-text>
          </c-flex>
        </c-modal-header>
        <c-modal-close-button />
        <MilestoneModal @onAddMilestone="onAddMilestone" />
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <c-alert-dialog
      :is-open="isAchievedDialogOpen"
      :on-close="closeAchievedDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <MilestoneAchieved
          :milestoneId="milestoneId"
          @closeModal="closeAchievedDialog"
          @onAchievedMilestone="onAchievedMilestone"
        />
      </c-alert-dialog-content>
    </c-alert-dialog>

    <TeamDrawer
      title="Key Resources"
      :isOpen="isTeamDrawerOpen"
      @close="closeTeamDrawer"
      v-if="isTeamDrawerOpen"
    />
  </c-flex>
</template>

<script>
import { generateID } from '@/helpers/data';
import {
  addKeyResource,
  deleteKeyResource,
  getKeyResources,
  updateKeyResource,
  getKeyActivities,
} from '@/services/business-model';
import EditableText from '../EditableText.vue';
import EssenceSelect from '../EssenceSelect.vue';
import GridTable from '../GridTable.vue';
import TeamDrawer from '@/views/App/components/OpenAiDrawer.vue';
import cloneDeep from 'lodash.clonedeep';

import { mapState } from 'vuex';
import AppSelect from '@/views/App/components/AppSelect.vue';
import MilestoneCell from './MilestoneCell.vue';
import MilestoneModal from './MilestoneModal.vue';
import MilestoneAchieved from './MilestoneAchieved.vue';

export default {
  props: {
    keyResourcesData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditableText,
    EssenceSelect,
    GridTable,
    AppSelect,
    MilestoneCell,
    TeamDrawer,
    MilestoneModal,
    MilestoneAchieved,
  },
  data() {
    return {
      activities: [],
      activityId: null,
      milestoneId: null,
      isAchievedDialogOpen: false,
      isMilestoneModalOpen: false,
      isUpdatingPlan: false,
      saved: false,
      isTeamDrawerOpen: false,
      currentResourceIndex: null,
      resourceTypes: [
        { label: 'Physical', value: 'PHYSICAL' },
        { label: 'Intellectual', value: 'INTELLECTUAL' },
        { label: 'Human', value: 'HUMAN' },
        { label: 'Financial', value: 'FINANCIAL' },
      ],
      resourceLevels: [
        {
          label: 'Critical Essential',
          value: 'CRITICAL_ESSENTIAL',
          color: '#37d5aa',
        },
        { label: 'Very Essential', value: 'VERY_ESSENTIAL', color: '#56b5db' },
        { label: ' Essential', value: 'ESSENTIAL', color: '#2d2d2d' },
        { label: 'Less Essential', value: 'LESS_ESSENTIAL', color: '#ef5323' },
        {
          label: 'Least Essential',
          value: 'LEAST_ESSENTIAL',
          color: '#ee2f1f',
        },
      ],
      resources: [],
      tableColumns: [
        {
          action: () => null,
          component: null,
          title: 'Name',
          value: 'name',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Type',
          value: 'type',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Essence',
          value: 'essence',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Key Activities',
          value: 'activities',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Description',
          value: 'description',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Employee Responsible',
          value: 'employee',
          disabled: false,
        },
        {
          action: () => null,
          component: null,
          title: 'Action Plan',
          value: 'milestone',
          disabled: false,
        },
      ],
      plans: [
        { label: 'Monitor', value: 'monitor' },
        { label: 'Act', value: 'act' },
        { label: 'Resolved', value: 'resolved' },
      ],
    };
  },
  created() {
    this.getResources();
    this.getActivities();
  },
  computed: {
    ...mapState('company', {
      teamMembers: (state) =>
        state.members.filter(
          (member) => member.isAccepted && member.user.isVerified
        ),
    }),
    ...mapState('config', {
      businessModelIds: (state) => state.businessModelIds,
    }),
  },
  methods: {
    onActivityChange(activityId, resourceIndex) {
      const resource = this.resources[resourceIndex];

      resource.resources_activities.push({
        key_resource_id: resource.id,
        key_activity_id: activityId,
        key_activity: this.activities.find(
          (activity) => activity.id == activityId
        ),
      });

      resource.resources_activities = resource.resources_activities.reduce(
        (acc, current) => {
          const x = acc.find(
            (item) => item.activity_id === current.activity_id
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        },
        []
      );

      this.saveResource({ ...resource }, resourceIndex);
    },
    removeActivity(activity, resourceIndex) {
      const resource = this.resources[resourceIndex];
      resource.resources_activities = resource.resources_activities.filter(
        (act) => act.key_activity_id !== activity.key_activity_id
      );

      this.saveResource({ ...resource }, resourceIndex);
    },
    openAiDrawer(index) {
      this.currentResourceIndex = index;
      this.isTeamDrawerOpen = true;
    },
    closeTeamDrawer(results) {
      if (!results) {
        this.isTeamDrawerOpen = false;
        return;
      }
      if (typeof results === 'object') {
        this.resources[this.currentResourceIndex].name = results.label;
        this.resources[this.currentResourceIndex].description =
          results.description;

        this.onFieldBlur(this.currentResourceIndex);
      }

      this.isTeamDrawerOpen = false;
    },
    closeMilestoneModal() {
      this.isMilestoneModalOpen = false;
    },
    closeAchievedDialog() {
      this.isAchievedDialogOpen = false;
    },
    updateDescription(val, keyIndex) {
      this.resources[keyIndex].description = val;
      this.onFieldBlur(keyIndex);
    },
    onActionPlanChange(e, resourceIndex) {
      switch (e) {
        case 'monitor': {
          this.isUpdatingPlan = true;
          this.resources[resourceIndex].actionPlan = 'monitor';
          this.resources[resourceIndex].milestoneId = null;
          this.onFieldBlur(resourceIndex);
          break;
        }
        case 'act': {
          this.isUpdatingPlan = true;
          this.resources[resourceIndex].actionPlan = 'act';
          this.currentResourceIndex = resourceIndex;
          this.isMilestoneModalOpen = true;
          break;
        }
        case 'resolved':
          this.isUpdatingPlan = true;
          this.resources[resourceIndex].actionPlan = 'resolved';
          this.currentResourceIndex = resourceIndex;
          this.milestoneId = this.resources[resourceIndex].milestoneId;
          this.isAchievedDialogOpen = true;
          break;
        default:
          break;
      }
    },
    onAddMilestone(milestoneId) {
      const activitiesArray = this.getActivitiesArray(
        this.currentResourceIndex
      );

      updateKeyResource({
        keyResourceId: this.resources[this.currentResourceIndex].id,
        objKeyResource: {
          milestoneId: milestoneId,
          actionPlan: 'act',
        },
        objRel: activitiesArray,
      })
        .then((response) => {
          this.resources[this.currentResourceIndex] =
            response.data.update_key_resource_by_pk;

          const milestone = response.data.update_key_resource_by_pk.milestone;
          this.resources[this.currentResourceIndex].milestone = {
            ...milestone,
          };

          this.isUpdatingPlan = false;
        })
        .catch(() => {});

      this.closeMilestoneModal();
    },
    onAchievedMilestone() {
      const activitiesArray = this.getActivitiesArray(
        this.currentResourceIndex
      );
      updateKeyResource({
        keyResourceId: this.resources[this.currentResourceIndex].id,
        objKeyResource: {
          actionPlan: 'resolved',
        },
        objRel: activitiesArray,
      })
        .then((response) => {
          this.resources[this.currentResourceIndex] = cloneDeep(
            response.data.update_key_resource_by_pk
          );
        })
        .catch(() => {});
      this.isUpdatingPlan = false;
      this.closeAchievedDialog();
    },
    getResources() {
      const modelIds = this.businessModelIds;
      this.$store.dispatch('config/setBusinessModelIds', {
        channels: modelIds.channels,
        keyActivities: modelIds.keyActivities,
        keyPartners: modelIds.keyPartners,
        keyResources: this.keyResourcesData.id,
      });
      getKeyResources(this.keyResourcesData.id).then((res) => {
        this.resources = res.data.key_resource.map((resource) => {
          return {
            ...resource,
            isNameValid: true,
          };
        });
      });
    },
    getActivities() {
      getKeyActivities(this.businessModelIds.keyActivities).then((res) => {
        this.activities = res.data.key_activity.map((activity) => {
          return {
            ...activity,
            isNameValid: true,
          };
        });
      });
    },
    addResource(results) {
      this.resources.push({
        id: null,
        type: 'FINANCIAL',
        name: results ? results.label : null,
        description: results ? results.description : null,
        essence: '',
        isNameValid: true,
        employeeResponsible: null,
        tempId: generateID(5),
        milestoneId: null,
      });
    },
    onNameInputChange(e, index) {
      if (typeof e === 'string') {
        this.resources[index].name = e;
      }
    },
    // onListInputBlur(index) {
    //   // this.resources[index].description = e;
    //   this.onFieldBlur(index);
    // },
    onFieldBlur(index) {
      const resource = this.resources[index];
      resource.isNameValid = resource.name && resource.name.trim();
      if (resource.isNameValid) {
        this.saveResource({ ...resource }, index);
      }

      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    },
    saveResource(resource, index) {
      const {
        id,
        name,
        type,
        description,
        essence,
        employeeResponsible,
        actionPlan,
        milestoneId,
      } = resource;
      if (id) {
        const activitiesArray = this.getActivitiesArray(index);

        return updateKeyResource({
          keyResourceId: id,
          objKeyResource: {
            name,
            type,
            description,
            essence,
            employeeResponsible,
            actionPlan,
            milestoneId,
          },
          objRel: activitiesArray,
        })
          .then((response) => {
            this.isUpdatingPlan = false;
            this.resources[index] = response.data.update_key_resource_by_pk;
          })
          .catch(() => {});
      } else {
        return addKeyResource({
          name,
          type,
          description,
          essence,
          businessModelId: this.keyResourcesData.id,
        })
          .then((res) => {
            this.resources[index].id = res.data.insert_key_resource_one.id;
          })
          .catch(() => {
            this.resources.splice(index, 1);
            this.$toast({
              title: 'An error occurred.',
              description: `Error while adding resource, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    getActivitiesArray(resourceIndex) {
      const activitiesArray = this.resources[
        resourceIndex
      ].resources_activities.map((activity) => {
        return {
          key_activity_id: activity.key_activity_id,
          key_resource_id: activity.key_resource_id,
        };
      });

      console.log(activitiesArray);

      return activitiesArray;
    },
    deleteResource(index) {
      const resource = this.resources.splice(index, 1)[0];
      if (resource.id) {
        deleteKeyResource(resource.id)
          .then(() => {})
          .catch(() => {
            this.resources.splice(index, 0, { ...resource });
            this.$toast({
              title: 'An error occurred.',
              description: `Error while deleting resource, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
      }
    },
    onRemoveMilestone(index) {
      this.saveResource({
        ...this.resources[index],
        milestoneId: null,
      }).then(() => {
        this.resources[index].milestone = null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.resource {
  ::v-deep {
    .vs__open-indicator {
      @apply block;
      fill: $color-orange;
      width: 10px;
      transform: scale(1) translateY(0);
      path {
        d: path(
          'M10.555,13.073l-2.8-2.8.933-.932,1.865,1.865L12.42,9.343l.933.932Z'
        );
        fill: red;
        transform: translate(-7.757px, -9.343px);
      }
    }
    .vs__dropdown-toggle {
      height: 25px;
      width: max-content;
      min-width: 80px;
      font-size: smaller;
      border: none;
      padding: 0;
    }
    .vs__selected {
      color: $color-orange;
      font-weight: bold;
      padding-left: 0px;
      margin-left: 0px;
    }
    .vs__selected-options {
      padding-left: 0px;
    }
    .vs__actions {
      padding: 8px 6px 0 3px;
    }
  }
}
</style>
