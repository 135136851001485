<template>
  <c-flex
    my="3"
    maxHeight="100%"
    w="100%"
    align="center"
    direction="column"
    justify="center"
  >
    <c-flex
      align="center"
      direction="column"
      h="calc(100% - 10px)"
      overflowY="scroll"
    >
      <template v-for="(customer, index) in selectedCustomers">
        <c-flex
          color="#fff"
          p="2"
          minHeight="30px"
          minWidth="95px"
          fontWeight="600"
          fontSize="xs"
          borderRadius="4px"
          mb="3"
          display="flex"
          align="center"
          justify="center"
          :backgroundColor="customerColorMap[customer.category]"
          :key="index"
          v-if="isSelectedCustomer(customer.id)"
        >
          {{ customer.name }}
        </c-flex>
      </template>
    </c-flex>
    <c-popover
      placement="bottom"
      zIndex="tooltip"
      v-if="!isPreview && !disabled"
      v-slot="parentPopoverSlotProps"
    >
      <c-popover-trigger>
        <c-pseudo-box
          as="a"
          display="flex"
          mr="2"
          alignItems="center"
          :_hover="{ textDecoration: 'none', cursor: 'pointer' }"
        >
          <svg
            v-chakra="{
              width: '18px',
              height: '18px',
              fill: '#ef5323',
              mr: '1',
            }"
          >
            <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
          </svg>
          <c-text color="#ef5323" fontWeight="600" fontSize="sm">
            Add Group
          </c-text>
        </c-pseudo-box>
      </c-popover-trigger>
      <c-popover-content width="max-content" zIndex="tooltip">
        <c-popover-body px="0">
          <c-list>
            <c-list-item fontSize="sm">
              <c-popover
                trigger="hover"
                :placement="'right'"
                v-slot="{ onClose }"
              >
                <c-popover-trigger>
                  <c-pseudo-box
                    as="a"
                    display="flex"
                    p="2"
                    px="3"
                    alignItems="center"
                    :_hover="{
                      textDecoration: 'none',
                      cursor: 'pointer',
                      bg: 'vc-orange.50',
                    }"
                    justifyContent="space-between"
                    fontSize="sm"
                    cursor="pointer"
                  >
                    <span> Your Groups </span>
                    <svg
                      v-chakra="{
                        width: '10px',
                        height: '10px',
                        fill: '#ef5323',
                        ml: '2',
                      }"
                    >
                      <use
                        href="@/assets/icons/icon-caret-right.svg#caret"
                      ></use>
                    </svg>
                  </c-pseudo-box>
                </c-popover-trigger>
                <c-popover-content width="max-content" zIndex="tooltip">
                  <c-popover-body p="0" width="max-content" zIndex="tooltip">
                    <c-list>
                      <c-list-item
                        fontWeight="600"
                        v-for="(customer, index) in customers"
                        :key="index"
                        :color="customerColorMap[customer.category]"
                      >
                        <c-pseudo-box
                          as="a"
                          :_hover="{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            bg: customerColorMap[customer.category],
                            color: 'white',
                          }"
                          role="group"
                          px="3"
                          py="2"
                          display="flex"
                          alignItems="center"
                          @click="
                            onCustomerSelect(
                              customer,
                              onClose,
                              parentPopoverSlotProps.onClose
                            )
                          "
                        >
                          <c-text mr="2"> {{ customer.name }}</c-text>

                          <c-icon
                            v-if="isSelectedCustomer(customer.id)"
                            name="check"
                            :color="customerColorMap[customer.category]"
                          ></c-icon>
                        </c-pseudo-box>
                      </c-list-item>
                    </c-list>
                  </c-popover-body>
                </c-popover-content>
              </c-popover>
            </c-list-item>
            <!-- <c-list-item
              color="vc-orange.400"
              p="2"
              fontSize="sm"
              fontWeight="600"
            >
              New Group
            </c-list-item> -->
          </c-list>
        </c-popover-body>
      </c-popover-content>
    </c-popover>
  </c-flex>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';

export default {
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('company', {
      customers: (state) => state.customers,
    }),
  },
  data() {
    return {
      selectedCustomers: [],
      customerColorMap: {
        government: '#3762d5',
        business: '#37d5aa',
        consumer: '#6bc1d1',
      },
    };
  },
  created() {
    if (this.value) {
      this.setSelectedCustomers();
    }
  },
  watch: {
    value() {
      this.setSelectedCustomers();
    },
  },
  methods: {
    onCustomerSelect(customer, closePopover, closeParentPopover) {
      if (this.isSelectedCustomer(customer.id)) {
        const index = this.selectedCustomers.findIndex(
          (_customer) => _customer.id === customer.id
        );
        this.selectedCustomers.splice(index, 1);
      } else {
        this.selectedCustomers.push({ ...customer });
      }
      closePopover();
      closeParentPopover();
      this.$emit('input', cloneDeep(this.selectedCustomers));
    },
    isSelectedCustomer(id) {
      return this.selectedCustomers.some((customer) => customer.id === id);
    },
    setSelectedCustomers() {
      this.selectedCustomers = this.value.map((customer) => {
        return {
          ...customer.customer,
        };
      });
    },
  },
};
</script>

<style></style>
